import React, { createContext, useContext, useMemo, useRef, useState, useCallback } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { taglineHeightsPerRow } from './taglineHeightsPerRow';

interface DynamicTaglineContextProps {
  addTagline: (id: string, el: HTMLSpanElement) => void;
  removeTagline: (id: string, el: HTMLSpanElement) => void;
  taglineHeights: Record<string, number>;
}

const DynamicTaglineContext = createContext<DynamicTaglineContextProps>(null!);

function mapValues<K, A, B>(transform: (x: A) => B, initial: Map<K, A>): Map<K, B> {
  return new Map(Array.from(initial).map(([k, v]) => [k, transform(v)]));
}

export const DynamicTaglineProvider: React.FC = ({ children }) => {
  const { isSSR } = useEnvironment();
  const taglinesMapRef = useRef<Map<string, HTMLSpanElement>>(useMemo(() => new Map(), []));
  const [taglineHeights, setTaglineHeights] = useState<Record<string, number>>({});

  const updateTaglineHeights = useCallback(() => {
    setTaglineHeights(taglineHeightsPerRow(mapValues((el) => el.getBoundingClientRect(), taglinesMapRef.current)));
  }, []);

  // isSSR is definitely not going to change during run-time.
  const observer = useMemo(
    () => (isSSR || !window.ResizeObserver ? null : new ResizeObserver(updateTaglineHeights)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const addTagline = useCallback(
    (id: string, el: HTMLSpanElement) => {
      if (!taglinesMapRef.current.has(id)) {
        taglinesMapRef.current.set(id, el);
        observer?.observe(el);
      }
    },
    [observer],
  );

  const removeTagline = useCallback(
    (id: string, el: HTMLSpanElement) => {
      observer?.unobserve(el);
      taglinesMapRef.current.delete(id);
      updateTaglineHeights();
    },
    [observer, updateTaglineHeights],
  );

  return (
    <DynamicTaglineContext.Provider
      value={useMemo(() => ({ addTagline, removeTagline, taglineHeights }), [
        addTagline,
        removeTagline,
        taglineHeights,
      ])}
    >
      {children}
    </DynamicTaglineContext.Provider>
  );
};

export const useDynamicTagline = () => useContext(DynamicTaglineContext);
