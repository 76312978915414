export function compressUuidArray(uuids: string[]): string[] {
  return uuids.map((id) => compressUuid(id));
}

export function uncompressUuidArray(compressedIds: string[]): string[] {
  return compressedIds.map((id) => uncompressUuid(id));
}

export function compressUuid(id: string): string {
  if (!id || !isUuid(id)) {
    return id;
  }

  const compressedId = hexStringToBase64(id.replace(/-/g, ''));
  const idWithoutPadding = compressedId.replace(/==$/, '');
  return idWithoutPadding;
}

export function uncompressUuid(compressedId: string): string {
  if (!compressedId) {
    return compressedId;
  }

  const idWithPadding = `${compressedId}==`;
  if (!isValidBase64(idWithPadding)) {
    return compressedId;
  }

  const uncompressedId = base64toHexString(idWithPadding);
  return [
    uncompressedId.substring(0, 8),
    uncompressedId.substring(8, 12),
    uncompressedId.substring(12, 16),
    uncompressedId.substring(16, 20),
    uncompressedId.substring(20),
  ].join('-');
}

function isUuid(str: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(str);
}

function isValidBase64(str: string): boolean {
  return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(str);
}

function hexStringToBase64(str: string): string {
  const shortened = str
    .match(/\w{2}/g)!
    .map((hexChars) => String.fromCharCode(parseInt(hexChars, 16)))
    .join('');
  return btoa(shortened);
}

function base64toHexString(str: string): string {
  return atob(str)
    .split('')
    .map((c) => {
      const charAsHex = c.charCodeAt(0).toString(16);
      return charAsHex.length === 1 ? `0${charAsHex}` : charAsHex;
    })
    .join('');
}
