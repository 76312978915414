const MIN_HEIGHT = 40;

export function taglineHeightsPerRow(
  taglinesById: Map<string, Pick<DOMRect, 'top' | 'height'>>,
): Record<string, number> {
  const newTaglineRows: Record<string, number> = {};
  groupByTop(taglinesById).forEach(({ maxHeight, taglines }) => {
    taglines.forEach((id) => (newTaglineRows[id] = maxHeight));
  });
  return newTaglineRows;
}

function groupByTop(taglinesById: Map<string, Pick<DOMRect, 'top' | 'height'>>) {
  const rows: Map<number, { maxHeight: number; taglines: string[] }> = new Map();
  taglinesById.forEach(({ top: row, height }, id) => {
    const { maxHeight, taglines } = rows.get(row) ?? { maxHeight: 0, taglines: [] };
    rows.set(row, { maxHeight: Math.max(maxHeight, normalizeHeight(height)), taglines: [id, ...taglines] });
  });
  return rows;
}

function normalizeHeight(height: any) {
  if (typeof height === 'number' && isFinite(height)) {
    return Math.max(40, height);
  }
  return MIN_HEIGHT;
}
