import { TFunction } from '@wix/yoshi-flow-editor';
import { AlertModal, ModalType } from '../types/common';

export type AlertModalContent = { title: string; subtitle: string; buttonText: string };

export const alertModalContent = (t: TFunction, modal: AlertModal): AlertModalContent => {
  switch (modal.type) {
    case ModalType.CannotAcceptPayment:
      return {
        title: t('modal.cannot-accept-payments.title'),
        subtitle: t('modal.cannot-accept-payments.subtitle'),
        buttonText: t('modal.cannot-accept-payments.button'),
      };
    case ModalType.PlanAlreadyPurchased:
      return {
        title: t('modal.plan-already-purchased.title', { planName: modal.planName }),
        subtitle: t('modal.plan-already-purchased.subtitle'),
        buttonText: t('modal.plan-already-purchased.button'),
      };
    case ModalType.PurchaseInPreview:
      return {
        title: t('pp.popups.purchase-in-preview-mode-header'),
        subtitle: t('pp.popups.purchase-in-preview-mode'),
        buttonText: t('pp.popups.purchase-in-preview-mode-cta'),
      };
  }
};
