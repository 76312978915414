import React, { useContext } from 'react';
import { PackagePickerStylesParams } from '../components/PackagePicker/stylesParams';

const StylesParamsContext = React.createContext<PackagePickerStylesParams>({} as PackagePickerStylesParams);

type StylesParamsProviderProps = {
  stylesParams: PackagePickerStylesParams;
};

export const StylesParamsProvider: React.FC<StylesParamsProviderProps> = ({ children, stylesParams }) => {
  return <StylesParamsContext.Provider value={stylesParams}>{children}</StylesParamsContext.Provider>;
};

export const useStylesParams = () => useContext(StylesParamsContext);
